/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onCreateSensors = /* GraphQL */ `subscription OnCreateSensors($filter: ModelSubscriptionSensorsFilterInput) {
  onCreateSensors(filter: $filter) {
    rssi
    battery
    temperature
    humidity
    timestamp
    mac
    name
    last_s3_save
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateSensorsSubscriptionVariables,
  APITypes.OnCreateSensorsSubscription
>;
export const onUpdateSensors = /* GraphQL */ `subscription OnUpdateSensors($filter: ModelSubscriptionSensorsFilterInput) {
  onUpdateSensors(filter: $filter) {
    rssi
    battery
    temperature
    humidity
    timestamp
    mac
    name
    last_s3_save
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateSensorsSubscriptionVariables,
  APITypes.OnUpdateSensorsSubscription
>;
export const onDeleteSensors = /* GraphQL */ `subscription OnDeleteSensors($filter: ModelSubscriptionSensorsFilterInput) {
  onDeleteSensors(filter: $filter) {
    rssi
    battery
    temperature
    humidity
    timestamp
    mac
    name
    last_s3_save
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteSensorsSubscriptionVariables,
  APITypes.OnDeleteSensorsSubscription
>;
export const onCreateSetup = /* GraphQL */ `subscription OnCreateSetup($filter: ModelSubscriptionSetupFilterInput) {
  onCreateSetup(filter: $filter) {
    id
    temp_last_report
    max_temperature
    report_interval_hours
    last_s3_save
    s3_save_interval_hours
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateSetupSubscriptionVariables,
  APITypes.OnCreateSetupSubscription
>;
export const onUpdateSetup = /* GraphQL */ `subscription OnUpdateSetup($filter: ModelSubscriptionSetupFilterInput) {
  onUpdateSetup(filter: $filter) {
    id
    temp_last_report
    max_temperature
    report_interval_hours
    last_s3_save
    s3_save_interval_hours
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateSetupSubscriptionVariables,
  APITypes.OnUpdateSetupSubscription
>;
export const onDeleteSetup = /* GraphQL */ `subscription OnDeleteSetup($filter: ModelSubscriptionSetupFilterInput) {
  onDeleteSetup(filter: $filter) {
    id
    temp_last_report
    max_temperature
    report_interval_hours
    last_s3_save
    s3_save_interval_hours
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteSetupSubscriptionVariables,
  APITypes.OnDeleteSetupSubscription
>;
