import React, { useContext } from "react";
import { formatDate, getColorForTemperature } from "../../pages/Sensors/state";
import Downloaded_data from "../../context/Donwloaded_data";

interface Entry {
  timestamp: string;
  temperature: number;
  humidity: number;
}

const WeatherCard = ({ data, max, min }: any): any => {
  const { downloaded_data, set_downloaded_data } = useContext(Downloaded_data);
  // console.log(data);
  return (
    <div className="p-2 m-2 rounded-lg shadow-xl bg-gray-200 w-40">
      <h2 className="text-xl font-semibold mb-2">{data.timestamp}</h2>
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center">
          <p
            style={{
              color: getColorForTemperature(data.temp, min, max),
            }}
            className="font-semibold text-2xl"
          >
            {data.temp}&deg;C
          </p>
        </div>
      </div>
      <p className="text-xs">Humidity</p>
      <p className="text-gray-600">{data.humidity}</p>
    </div>
  );
};

export default WeatherCard;
