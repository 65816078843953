import React, { useState, createContext } from "react";
import PropTypes from "prop-types"; // Import PropTypes

const Curr_temps = createContext({
  curr_temps: new Object(),
  set_curr_temps: (_) => {
    //
  },
});

export const Curr_temp_provider = ({ children }) => {
  const [curr_temps, set_curr_temps] = useState(new Object());

  const value = {
    curr_temps,
    set_curr_temps,
  };

  return <Curr_temps.Provider value={value}>{children}</Curr_temps.Provider>;
};

// Add prop validation for the children prop
Curr_temp_provider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Curr_temps;
