import React, { useContext, useEffect, useState } from "react";
import { IonCard, IonPage, IonText } from "@ionic/react";
import "./styles.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "react-datepicker/dist/react-datepicker.css";
import { Card } from "primereact/card";
import TemperatureCard from "../../components/temp_card";
import { withRouter } from "react-router-dom";
import Curr_temps from "../../context/Curr_temps";
import Sensors from "../../context/Sensors";
import Tfg_data from "../../context/Tfg_data";
import Loading from "../../context/Loading";

// Change env

// test
// const s3_pdf_file_name = "test_temp_data";
// const sub_mail_to = "test_notifications";
// const site_name = "Test";
// const json_start_date = new Date("2024-03-04");

// Midrand
export const sub_mail_to = "tfg_temp_increase";
export const s3_prifix = "midrand_";
export const site_name = "Midrand";
export const json_start_date = new Date("2023-06-04");
const top_sensors = "MEZ";
const ground_sensors = "GROUND";

// Riverfield
// export const sub_mail_to = "north_dc_temp_warnings";
// export const s3_prifix = "riverfield_";
// export const site_name = "Riverfields";
// export const json_start_date = new Date("2023-11-01");
// export const top_sensors = "Top";
// export const ground_sensors = "Ground";

// Cape Towm
// const s3_pdf_file_name = "capetown_report";
// const sub_mail_to = "capetown_temp_increase";
// const site_name = "Cape Town";

export const url =
  "https://z9unyhq478.execute-api.us-east-1.amazonaws.com/default/download_temp_data";

const Home = () => {
  const { sensors } = useContext(Sensors);
  const { curr_temps }: any = useContext(Curr_temps);
  const { tfg_data }: any = useContext(Tfg_data);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  return (
    <IonPage style={{ flex: 1, overflow: "auto", marginTop: "10vh" }}>
      {/* list */}
      {!!Object.keys(curr_temps)?.length && (
        <>
          <Card
            title="Top Temperature"
            className="flex p-3 justify-content-center"
          />
          <div>
            <IonCard>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <IonText
                  className="ave_temp_text"
                  style={{
                    lineHeight: 1,
                    color:
                      Object.values(curr_temps)
                        .map((ble: any) => ble.temperature)
                        .reduce((acc, val) => acc + val, 0) /
                        Object.values(curr_temps).length >
                      tfg_data.max_temperature
                        ? "#B7121F"
                        : "#22A7F0",
                  }}
                >
                  {Object.keys(curr_temps).length
                    ? (
                        Object.values(curr_temps)
                          .filter(
                            (ble: any) =>
                              ble.name && ble.name.includes(top_sensors)
                          )
                          .map((ble: any) => ble.temperature)
                          .reduce((acc, val) => acc + val, 0) /
                        Object.values(curr_temps).filter(
                          (ble: any) =>
                            ble.name && ble.name.includes(top_sensors)
                        ).length
                      ).toFixed(1)
                    : 0}
                  °C
                </IonText>
                <IonText
                  style={{
                    alignSelf: "flex-start  ",
                    color:
                      Object.values(curr_temps)
                        .map((ble: any) => ble.temperature)
                        .reduce((acc, val) => acc + val, 0) /
                        Object.values(curr_temps).length >
                      tfg_data.max_temperature
                        ? "#B7121F"
                        : "#22A7F0",
                  }}
                >
                  <p>
                    Top. Ave
                    <br /> Temperature
                  </p>
                </IonText>
              </div>
            </IonCard>
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "row",
              margin: "0 10px",
              marginBottom: "20px",
              justifyContent: "center",
            }}
          >
            {Object.values(curr_temps)
              .filter((ble: any) => ble.name && ble.name.includes(top_sensors))
              .map((ble: any, i: any) => (
                <TemperatureCard
                  key={i}
                  ble={ble}
                  color={
                    ble.temperature > tfg_data.max_temperature
                      ? "#B7121F"
                      : "#22A7F0"
                  }
                  percent={
                    ble.temperature / tfg_data.max_temperature > 1
                      ? 1
                      : parseFloat(
                          (ble.temperature / tfg_data.max_temperature).toFixed(
                            2
                          )
                        )
                  }
                />
              ))}
          </div>
        </>
      )}

      <div>
        <IonCard>
          <Card
            title="Ground Temperature"
            className="flex p-3 justify-content-center"
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IonText
              className="ave_temp_text"
              style={{
                lineHeight: 1,
                color:
                  Object.values(curr_temps)
                    .filter(
                      (ble: any) =>
                        ble.name && ble.name.includes(ground_sensors)
                    )
                    .map((ble: any) => ble.temperature)
                    .reduce((acc, val) => acc + val, 0) /
                    Object.values(curr_temps).filter(
                      (ble: any) =>
                        ble.name && ble.name.includes(ground_sensors)
                    ).length >
                  tfg_data.max_temperature
                    ? "#B7121F"
                    : "#22A7F0",
              }}
            >
              {Object.keys(curr_temps).length
                ? (
                    Object.values(curr_temps)
                      .filter(
                        (ble: any) =>
                          ble.name && ble.name.includes(ground_sensors)
                      )

                      .map((ble: any) => ble.temperature)
                      .reduce((acc, val) => acc + val, 0) /
                    Object.values(curr_temps).filter(
                      (ble: any) =>
                        ble.name && ble.name.includes(ground_sensors)
                    ).length
                  ).toFixed(1)
                : 0}
              °C
            </IonText>

            <IonText
              style={{
                alignSelf: "flex-start  ",
                color:
                  Object.values(curr_temps)
                    .filter(
                      (ble: any) =>
                        ble.name && ble.name.includes(ground_sensors)
                    )
                    .map((ble: any) => ble.temperature)
                    .reduce((acc, val) => acc + val, 0) /
                    Object.values(curr_temps).filter(
                      (ble: any) =>
                        ble.name && ble.name.includes(ground_sensors)
                    ).length >
                  tfg_data.max_temperature
                    ? "#B7121F"
                    : "#22A7F0",
              }}
            >
              <p>
                Ground Ave.
                <br /> Temperature
              </p>
            </IonText>
          </div>
        </IonCard>
      </div>
      {/* list */}

      {!!Object.keys(curr_temps)?.length && (
        <>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "row",
              margin: "0 10px",
              marginBottom: "20px",
              justifyContent: "center",
            }}
          >
            {Object.values(curr_temps)
              .filter(
                (ble: any) => ble.name && ble.name.includes(ground_sensors)
              )
              .map((ble: any, i: any) => (
                <TemperatureCard
                  key={i}
                  ble={ble}
                  color={
                    ble.temperature > tfg_data.max_temperature
                      ? "#B7121F"
                      : "#22A7F0"
                  }
                  percent={
                    ble.temperature / tfg_data.max_temperature > 1
                      ? 1
                      : parseFloat(
                          (ble.temperature / tfg_data.max_temperature).toFixed(
                            2
                          )
                        )
                  }
                />
              ))}
          </div>
        </>
      )}
      {!!sensors?.length && (
        <>
          <Card
            title="Last Recorded Temperature"
            className="flex p-3 justify-content-center"
          />
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "row",
              margin: "0 10px",
              marginBottom: "20px",
              justifyContent: "center",
            }}
          >
            {sensors
              .filter((ble: any) => !curr_temps[ble.mac])
              .map((ble: any, i: any) => (
                <TemperatureCard
                  key={i}
                  ble={ble}
                  color={
                    ble.temperature > tfg_data.max_temperature
                      ? "#B7121F"
                      : "#22A7F0"
                  }
                  percent={
                    ble.temperature / tfg_data.max_temperature > 1
                      ? 1
                      : parseFloat(
                          (ble.temperature / tfg_data.max_temperature).toFixed(
                            2
                          )
                        )
                  }
                />
              ))}
          </div>
        </>
      )}
    </IonPage>
  );
};

export default withRouter(Home);
