import React, { useState, createContext } from "react";
import PropTypes from "prop-types"; // Import PropTypes

const Sensors_list = createContext({
  sensors: [],
  set_sensors: (_) => {
    //
  },
});

export const SensorsProvider = ({ children }) => {
  const [sensors, set_sensors] = useState([]);

  const value = {
    sensors,
    set_sensors,
  };

  return (
    <Sensors_list.Provider value={value}>{children}</Sensors_list.Provider>
  );
};

// Add prop validation for the children prop
SensorsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Sensors_list;
