import React, { useState, createContext } from "react";
import PropTypes from "prop-types"; // Import PropTypes

const Loading = createContext({
  loading: false,
  set_loading: (_) => {
    //
  },
});

export const LoadingProvider = ({ children }) => {
  const [loading, set_loading] = useState(false);

  const value = {
    loading,
    set_loading,
  };

  return <Loading.Provider value={value}>{children}</Loading.Provider>;
};

// Add prop validation for the children prop
LoadingProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Loading;
