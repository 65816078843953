import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { opts } from "../../pages/Sensors/state";

const Hourly_Graph = ({ data }: any) => {
  // useEffect(() => {
  //   console.log(
  //     data?.map((hour: any) => hour.hour.toString().padStart(2, "0") + ":00")
  //   );
  // }, [data]);

  const generateColors = () => {
    const randomColor = () => Math.floor(Math.random() * 256);
    const randomRGB = `rgba(${randomColor()}, ${randomColor()}, ${randomColor()}, 0.2)`;
    return {
      backgroundColor: randomRGB,
      borderColor: randomRGB,
    };
  };

  if (!data?.length) return <></>;

  return (
    <div>
      <div className="flex flex-row justify-content-evenly align-items-center">
        <div className="flex-1 flex flex-column m-3">
          <h1 className="my-4 text-3xl md:text-5xl font-bold ">
            Showing <span className="text-blue-500">Temperature </span>
          </h1>

          <Line
            datasetIdKey="id"
            options={
              {
                ...opts,
                plugins: {
                  legend: {
                    display: false,
                  },
                },
              } as any
            }
            data={
              {
                labels: data?.map(
                  (hour: any) => hour.hour.toString().padStart(2, "0") + ":00"
                ),
                datasets: [
                  {
                    ...generateColors(), // Use stored colors
                    id: "hour",
                    label: "Temp",
                    data: data?.map((day: any) => day.temp),
                  },
                ],
              } as any
            }
          />
        </div>

        <div className="flex-1 flex flex-column m-3">
          <h1 className="my-4 text-3xl md:text-5xl font-bold ">
            Showing <span className="text-blue-500">Humidity</span>
          </h1>

          <Line
            datasetIdKey="id"
            options={
              {
                ...opts,
                plugins: {
                  legend: {
                    display: false,
                  },
                },
              } as any
            }
            data={
              {
                labels: data?.map(
                  (hour: any) => hour.hour.toString().padStart(2, "0") + ":00"
                ),
                datasets: [
                  {
                    ...generateColors(), // Use stored colors
                    id: "humidity",
                    label: "Humididy",
                    data: data?.map((day: any) => day.humidity),
                  },
                ],
              } as any
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Hourly_Graph;
