import React, { useRef } from "react";
import { IonApp, setupIonicReact } from "@ionic/react";
import { Amplify } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import "primereact/resources/themes/lara-light-cyan/theme.css";

import awsconfig from "./aws-exports";
Amplify.configure(awsconfig);

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import Stack from "./Stack";
import Toast_message from "./context/Toast_message";
import { LoadingProvider } from "./context/Loading";
import { Downloaded_data_provider } from "./context/Donwloaded_data";
import { SensorsProvider } from "./context/Sensors";
import { Curr_temp_provider } from "./context/Curr_temps";
import { Tfg_data_provider } from "./context/Tfg_data";
import "./index.css";
setupIonicReact();

const App: React.FC = () => {
  const toast = useRef(null);

  return (
    <IonApp>
      <Tfg_data_provider>
        <Curr_temp_provider>
          <LoadingProvider>
            <Downloaded_data_provider>
              <Toast_message.Provider value={{ toast }}>
                <SensorsProvider>
                  <Stack />
                </SensorsProvider>
              </Toast_message.Provider>
            </Downloaded_data_provider>
          </LoadingProvider>
        </Curr_temp_provider>
      </Tfg_data_provider>
    </IonApp>
  );
};
export default App;
