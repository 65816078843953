/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createSensors = /* GraphQL */ `mutation CreateSensors(
  $input: CreateSensorsInput!
  $condition: ModelSensorsConditionInput
) {
  createSensors(input: $input, condition: $condition) {
    rssi
    battery
    temperature
    humidity
    timestamp
    mac
    name
    last_s3_save
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSensorsMutationVariables,
  APITypes.CreateSensorsMutation
>;
export const updateSensors = /* GraphQL */ `mutation UpdateSensors(
  $input: UpdateSensorsInput!
  $condition: ModelSensorsConditionInput
) {
  updateSensors(input: $input, condition: $condition) {
    rssi
    battery
    temperature
    humidity
    timestamp
    mac
    name
    last_s3_save
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSensorsMutationVariables,
  APITypes.UpdateSensorsMutation
>;
export const deleteSensors = /* GraphQL */ `mutation DeleteSensors(
  $input: DeleteSensorsInput!
  $condition: ModelSensorsConditionInput
) {
  deleteSensors(input: $input, condition: $condition) {
    rssi
    battery
    temperature
    humidity
    timestamp
    mac
    name
    last_s3_save
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSensorsMutationVariables,
  APITypes.DeleteSensorsMutation
>;
export const createSetup = /* GraphQL */ `mutation CreateSetup(
  $input: CreateSetupInput!
  $condition: ModelSetupConditionInput
) {
  createSetup(input: $input, condition: $condition) {
    id
    temp_last_report
    max_temperature
    report_interval_hours
    last_s3_save
    s3_save_interval_hours
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSetupMutationVariables,
  APITypes.CreateSetupMutation
>;
export const updateSetup = /* GraphQL */ `mutation UpdateSetup(
  $input: UpdateSetupInput!
  $condition: ModelSetupConditionInput
) {
  updateSetup(input: $input, condition: $condition) {
    id
    temp_last_report
    max_temperature
    report_interval_hours
    last_s3_save
    s3_save_interval_hours
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSetupMutationVariables,
  APITypes.UpdateSetupMutation
>;
export const deleteSetup = /* GraphQL */ `mutation DeleteSetup(
  $input: DeleteSetupInput!
  $condition: ModelSetupConditionInput
) {
  deleteSetup(input: $input, condition: $condition) {
    id
    temp_last_report
    max_temperature
    report_interval_hours
    last_s3_save
    s3_save_interval_hours
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSetupMutationVariables,
  APITypes.DeleteSetupMutation
>;
