import { Divider } from "@aws-amplify/ui-react";
import React, { useEffect, useState } from "react";
import { Bar, Line } from "react-chartjs-2";
import WeatherCard from "../temp_day_card";
import {
  generateColors,
  getColorForTemperature,
  opts,
} from "../../pages/Sensors/state";

function Daily_({ statistics }: any) {
  const [colors, setColors] = useState({} as any);

  useEffect(() => {
    // Generate colors for each dataset
    setColors(generateColors(statistics, "mac"));
  }, [statistics]);

  return (
    <>
      <div className="p-4  ">
        {/* <h1 className="my-4 text-3xl md:text-5xl font-bold ">
          All <span className="text-blue-500">Sensors</span>
        </h1> */}
        <Divider />
        <div className="p-grid p-jc-between p-mb-4 ">
          <div className="flex flex-column  my-4">
            <div className="card bg-base-100 shadow-xl bg-gray-200 mb-4">
              <div className="card-body">
                <p className="stat-title">
                  Hourly average temperature and Humidity per sensor
                </p>
              </div>
            </div>
            <div className="flex flex-row justify-content-evenly align-items-center">
              <div className="flex-1 flex flex-column">
                <h1 className="my-4 text-3xl md:text-5xl font-bold ">
                  <span className="text-blue-500">Temperature </span>- Hourly
                  Average
                </h1>
                <Line
                  datasetIdKey="id"
                  options={opts}
                  data={
                    {
                      labels: statistics
                        .reduce((prev: any, current: any) =>
                          prev.data.length > current.data.length
                            ? prev
                            : current
                        )
                        .data.map(
                          (entry: any) =>
                            new Date(entry.timestamp)
                              .getHours()
                              .toString()
                              .padStart(2, "0") + ":00"
                        ),

                      datasets: statistics.map((sensors: any) => {
                        return {
                          ...colors[sensors.mac], // Use stored colors
                          id: sensors.mac,
                          label: sensors.name,
                          data: sensors.data.map((day: any) => day.temperature),
                        };
                      }),
                    } as any
                  }
                />
              </div>
              <div className="flex-1 flex flex-column">
                <h1 className="my-4 text-3xl md:text-5xl font-bold ">
                  <span className="text-blue-500">Humidity </span>- Hourly
                  Average
                </h1>
                <Line
                  datasetIdKey="id"
                  options={opts}
                  data={
                    {
                      labels: statistics
                        .reduce((prev: any, current: any) =>
                          prev.data.length > current.data.length
                            ? prev
                            : current
                        )
                        .data.map(
                          (entry: any) =>
                            new Date(entry.timestamp)
                              .getHours()
                              .toString()
                              .padStart(2, "0") + ":00"
                        ),

                      datasets: statistics.map((sensors: any) => {
                        return {
                          ...colors[sensors.mac], // Use stored colors
                          id: sensors.mac,
                          label: sensors.name,
                          data: sensors.data.map((day: any) => day.humidity),
                        };
                      }),
                    } as any
                  }
                />
              </div>
            </div>
          </div>
          {/* <div className="flex flex-col items-start mt-4">
            <p className="text-sm text-gray-600">Humidity Readings:</p>
             <Bar data={chartData} options={chartOptions} /> 
          </div> */}
        </div>
      </div>
    </>
  );
}

export default Daily_;
