/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getSensors = /* GraphQL */ `query GetSensors($mac: ID!) {
  getSensors(mac: $mac) {
    rssi
    battery
    temperature
    humidity
    timestamp
    mac
    name
    last_s3_save
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSensorsQueryVariables,
  APITypes.GetSensorsQuery
>;
export const listSensors = /* GraphQL */ `query ListSensors(
  $mac: ID
  $filter: ModelSensorsFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listSensors(
    mac: $mac
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      rssi
      battery
      temperature
      humidity
      timestamp
      mac
      name
      last_s3_save
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSensorsQueryVariables,
  APITypes.ListSensorsQuery
>;
export const getSetup = /* GraphQL */ `query GetSetup($id: ID!) {
  getSetup(id: $id) {
    id
    temp_last_report
    max_temperature
    report_interval_hours
    last_s3_save
    s3_save_interval_hours
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetSetupQueryVariables, APITypes.GetSetupQuery>;
export const listSetups = /* GraphQL */ `query ListSetups(
  $filter: ModelSetupFilterInput
  $limit: Int
  $nextToken: String
) {
  listSetups(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      temp_last_report
      max_temperature
      report_interval_hours
      last_s3_save
      s3_save_interval_hours
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSetupsQueryVariables,
  APITypes.ListSetupsQuery
>;
