function getYearMonthRange(data: any) {
  if (!data.length) return [];
  const yearMonths: any = [];

  data.forEach((entry: any) => {
    const timestamp = new Date(entry.timestamp);
    timestamp.setHours(timestamp.getHours() - 2); // Subtract 2 hours
    const year = timestamp.getFullYear();
    const month = timestamp.getMonth() + 1; // Adding 1 because months are zero-indexed
    const yearMonth = `${year} ${month < 10 ? "0" + month : month}`;

    // Check if the year-month string already exists in the array
    if (!yearMonths.includes(yearMonth)) {
      yearMonths.push(yearMonth);
    }
  });

  return yearMonths;
}

interface Entry {
  timestamp: string;
  temperature: number;
  humidity: number;
}

interface DailyWeather {
  day: number;
  timestamp: string;
  temp: number;
  humidity: number;
}
const month_names = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
function formatDate(timestamp: any) {
  const date = new Date(timestamp);
  const day = date.getDate();
  const monthIndex = date.getMonth();

  // Define an array of month names

  // Format the date
  const formattedDate = `${day} ${month_names[monthIndex]}`;

  return formattedDate;
}

function calculateDailyTemperatureAverage(
  data: Entry[],
  year: number,
  month: number
): DailyWeather[] {
  const dailyAverages: DailyWeather[] = [];

  // Filter data for the specified month
  const filteredData = data.filter((entry) => {
    const timestamp = new Date(entry.timestamp);
    return (
      timestamp.getFullYear() === year && timestamp.getMonth() + 1 === month
    );
  });

  // Get unique days in the month from the filtered data
  const uniqueDays = Array.from(
    new Set(filteredData.map((entry) => new Date(entry.timestamp).getDate()))
  );

  // Iterate over each unique day in the month
  uniqueDays.forEach((day) => {
    // Filter entries for the current day and time range (08:00 to 16:00)
    const entriesForDay = filteredData.filter((entry) => {
      const timestamp = new Date(entry.timestamp);
      return (
        timestamp.getDate() === day &&
        timestamp.getHours() >= 8 &&
        timestamp.getHours() <= 16
      );
    });
    // Calculate average temperature and humidity for the day
    if (entriesForDay.length > 0) {
      let tempSum = 0;
      let humiditySum = 0;
      entriesForDay.forEach((entry) => {
        tempSum += entry.temperature;
        humiditySum += entry.humidity;
      });
      const tempAverage = tempSum / entriesForDay.length;
      const humidityAverage = humiditySum / entriesForDay.length;

      // Find the timestamp for the first entry of the day
      const timestampForDay = entriesForDay[0].timestamp;
      dailyAverages.push({
        timestamp: timestampForDay,
        day,
        temp: parseFloat(tempAverage.toFixed(2)),
        humidity: parseFloat(humidityAverage.toFixed(2)),
      });
    }
  });

  return dailyAverages;
}

function getColorForTemperature(temperature: any, minTemp = 16, maxTemp = 33) {
  // Define the minimum and maximum temperatures of your range

  // Define the corresponding colors for the minimum and maximum temperatures
  const minColor = [0, 0, 255]; // Blue (for minTemp)
  const maxColor = [255, 0, 0]; // Red (for maxTemp)

  // Calculate the normalized value of the temperature within the range
  const normalizedTemp = (temperature - minTemp) / (maxTemp - minTemp);

  // Interpolate between the minColor and maxColor based on the normalized temperature
  const interpolatedColor = [
    Math.round(
      (1 - normalizedTemp) * minColor[0] + normalizedTemp * maxColor[0]
    ),
    Math.round(
      (1 - normalizedTemp) * minColor[1] + normalizedTemp * maxColor[1]
    ),
    Math.round(
      (1 - normalizedTemp) * minColor[2] + normalizedTemp * maxColor[2]
    ),
  ];

  // Convert the interpolated RGB values to a hex color code
  const colorHex =
    "#" +
    interpolatedColor
      .map((component) => {
        const hex = component.toString(16); // Convert the decimal value to hexadecimal
        return hex.length === 1 ? "0" + hex : hex; // Add leading zero if necessary
      })
      .join("");

  return colorHex;
}

function getCurrentMonthAndYear(data: any) {
  // Extract timestamps and convert them to ISO strings

  const isoStrings = data.map((entry: any) => {
    const timestamp = new Date(entry.timestamp);
    // Subtract 2 hours (7200000 milliseconds) to adjust for time zone offset
    timestamp.setTime(timestamp.getTime() - 7200000);
    return timestamp;
  });

  // Find the maximum ISO string
  const latestISO = new Date(Math.max.apply(null, isoStrings));

  // Extract year and month from the maximum ISO string
  const year = latestISO.getFullYear();
  const month = latestISO.getMonth() + 1; // Adding 1 because months are zero-indexed

  // Return the latest year and month in the desired format
  return `${year} ${month < 10 ? "0" + month : month}`;
}

function get_min_max(data: any) {
  // Check if the data array is empty
  if (data.length === 0) {
    return {
      max_temp: null,
      min_temp: null,
      max_humidity: null,
      min_humidity: null,
    };
  }

  // Initialize maxTemp and minTemp with the first temperature value in the array
  let max_temp = data[0]?.temperature;
  let min_temp = data[0]?.temperature;
  let max_humidity = data[0]?.humidity;
  let min_humidity = data[0]?.humidity;

  // Iterate over the array to find the maximum and minimum temperatures and humidity
  data.forEach((item: any) => {
    const timestamp = new Date(item.timestamp);
    if (timestamp.getHours() >= 8 && timestamp.getHours() <= 16) {
      const temperature = item.temperature;
      const humidity = item.humidity;
      if (temperature > max_temp) {
        max_temp = temperature; // Update maxTemp if a higher temperature is found
      }
      if (temperature < min_temp) {
        min_temp = temperature; // Update minTemp if a lower temperature is found
      }
      if (humidity > max_humidity) {
        max_humidity = humidity; // Update maxHumidity if a higher humidity is found
      }
      if (humidity < min_humidity) {
        min_humidity = humidity; // Update minHumidity if a lower humidity is found
      }
    }
  });

  // Return the maximum and minimum temperatures and humidity
  return { max_temp, min_temp, max_humidity, min_humidity };
}
function mac_stats(data: any, yearMonthString: string) {
  // Parse year and month from the provided string
  const [year, month] = yearMonthString.split(" ");

  // Initialize an object to store statistics
  const statistics: any = {};

  // Iterate over each sensor data
  data.forEach((sensor: any) => {
    // Extract sensor details
    const { name, temperature, humidity, mac, timestamp }: any = sensor;

    // Get the year and month portion from the timestamp
    const timestampYearMonth = timestamp.slice(0, 7);

    // Check if the year and month match the provided string
    if (timestampYearMonth === `${year}-${month}`) {
      // Initialize statistics for the sensor name if not exists
      if (!statistics[mac]) {
        statistics[mac] = {
          temperature: [],
          humidity: [],
          mac,
          name,
          maxTemperature: -Infinity,
          minTemperature: Infinity,
          maxHumidity: -Infinity, // New property for max humidity
          minHumidity: Infinity, // New property for min humidity
          totalTemperature: 0,
          totalHumidity: 0,
          count: 0,
        };
      }

      // Update max temperature
      if (temperature > statistics[mac].maxTemperature) {
        statistics[mac].maxTemperature = temperature;
      }

      // Update min temperature
      if (temperature < statistics[mac].minTemperature) {
        statistics[mac].minTemperature = temperature;
      }

      // Update max humidity
      if (humidity > statistics[mac].maxHumidity) {
        statistics[mac].maxHumidity = humidity;
      }

      // Update min humidity
      if (humidity < statistics[mac].minHumidity) {
        statistics[mac].minHumidity = humidity;
      }

      // Accumulate the sum of temperature and humidity for averaging
      statistics[mac].totalTemperature += temperature;
      statistics[mac].totalHumidity += humidity;
      statistics[mac].count++;

      // Push temperature and humidity data
      const day: any = timestamp.slice(0, 10);

      // Check if the day already exists in the temperature array
      const existingDayTemp = statistics[mac].temperature.find(
        (entry: any) => entry.day === day
      );
      if (!existingDayTemp) {
        // Add temperature data for the day if it doesn't exist
        statistics[mac].temperature.push({ day, value: temperature });
      } else {
        // Update the existing day's temperature average
        existingDayTemp.value = parseFloat(
          ((existingDayTemp.value + temperature) / 2).toFixed(2)
        );
      }

      // Check if the day already exists in the humidity array
      const existingDayHumidity = statistics[mac].humidity.find(
        (entry: any) => entry.day === day
      );
      if (!existingDayHumidity) {
        // Add humidity data for the day if it doesn't exist
        statistics[mac].humidity.push({ day, value: humidity });
      } else {
        // Update the existing day's humidity average
        existingDayHumidity.value = parseFloat(
          ((existingDayHumidity.value + humidity) / 2).toFixed(2)
        );
      }
    }
  });

  // Calculate average temperature and humidity for each sensor
  for (const mac in statistics) {
    const sensorStats = statistics[mac];

    // Calculate average temperature
    sensorStats.avgTemperature =
      sensorStats.totalTemperature / sensorStats.count;
    sensorStats.avgTemperature = parseFloat(
      sensorStats.avgTemperature.toFixed(2)
    );

    // Calculate average humidity
    sensorStats.avgHumidity = sensorStats.totalHumidity / sensorStats.count;
    sensorStats.avgHumidity = parseFloat(sensorStats.avgHumidity.toFixed(2));
  }

  return statistics;
}

function name_sensors(data: any, macsToCompare: any) {
  data.forEach((ble: any) => {
    const obj = macsToCompare.find((ble_: any) => ble_.mac == ble.mac);
    if (obj && obj.name) {
      ble.name = obj.name;
    }
  });

  return [...data];
}
function sortDataByHourly(data: any, selectedDay: any) {
  const hourlyData: any = {};

  // Filter data for the selected day
  const filteredData = data.filter((item: any) => {
    const date = item.timestamp.includes("T")
      ? item.timestamp.split("T")[0]
      : item.timestamp.split(" ")[0];
    return date === selectedDay;
  });

  // Group data by hour and calculate average temperature, humidity, and find MAC address with highest temperature
  filteredData.forEach((item: any) => {
    let timestamp = item.timestamp;
    // Check if the timestamp contains 'T'
    if (timestamp.includes("T")) {
      timestamp = timestamp.replace("T", " "); // Replace 'T' with space
    }
    timestamp = new Date(timestamp); // Convert timestamp to Date object
    const hour = timestamp.getHours();
    const temperature = item.temperature;
    const humidity = item.humidity;
    const mac = item.mac;

    if (hour in hourlyData) {
      hourlyData[hour].count += 1;
      hourlyData[hour].totalTemperature += temperature;
      hourlyData[hour].totalHumidity += humidity;
      if (temperature > hourlyData[hour].maxTemperature) {
        hourlyData[hour].maxTemperature = temperature;
        hourlyData[hour].macWithMaxTemp = mac;
      }
    } else {
      hourlyData[hour] = {
        count: 1,
        totalTemperature: temperature,
        totalHumidity: humidity,
        maxTemperature: temperature,
        macWithMaxTemp: mac,
      };
    }
  });

  // Calculate average temperature and humidity for each hour and prepare the result array
  const resultArray = [];
  for (const hour in hourlyData) {
    const hourData = hourlyData[hour];
    const avgTemperature = hourData.totalTemperature / hourData.count;
    const avgHumidity = hourData.totalHumidity / hourData.count;
    resultArray.push({
      hour: parseInt(hour),
      temp: avgTemperature.toFixed(2),
      humidity: avgHumidity.toFixed(2),
      macWithMaxTemp: hourData.macWithMaxTemp,
    });
  }

  return resultArray;
}
const opts = {
  scales: {
    y: {
      grid: {
        display: false, // Hides the y-axis grid lines
      },
    },
    x: {
      grid: {
        display: false, // Hides the x-axis grid lines
      },
    },
  },
};

const latestDate = (dateValue: any, dataArray: any) => {
  // Parse the passed dateValue
  const [year, month] = dateValue.split(" ").map(Number);

  // Filter the array for items that match the year and month
  const filteredArray = dataArray.filter((item: any) => {
    const itemDate = new Date(item.timestamp);
    return itemDate.getFullYear() === year && itemDate.getMonth() + 1 === month;
  });

  // Sort the filtered array by timestamp in descending order
  filteredArray.sort(
    (a: any, b: any) =>
      new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
  );

  // Return the latest date if array is not empty, otherwise return null
  return filteredArray.length > 0
    ? filteredArray[0].timestamp.slice(0, 10)
    : null;
};
const generateColors = (statistics: any, key: any) => {
  const colorsObject: any = {};
  Object.values(statistics).forEach((sensors: any) => {
    const randomColor = () => Math.floor(Math.random() * 256);
    const randomRGB = `rgba(${randomColor()}, ${randomColor()}, ${randomColor()}, 0.2)`;
    colorsObject[sensors[key]] = {
      backgroundColor: randomRGB,
      borderColor: randomRGB,
    };
  });

  return colorsObject;
};

function calculateAverages(data: any) {
  // Object to store the sum of temperatures, sum of humidities, and count of readings for each MAC address
  const macData: any = {};

  // Iterate through each data object
  data.forEach((item: any) => {
    const mac = item.mac;
    const temperature = item.temperature;
    const humidity = item.humidity;

    if (!macData[mac]) {
      macData[mac] = { tempSum: 0, humiditySum: 0, count: 0 };
    }

    macData[mac].tempSum += temperature;
    macData[mac].humiditySum += humidity;
    macData[mac].count += 1;
  });

  // Create an array to store the result
  const result = [];

  // Calculate the average temperature and humidity for each MAC address
  for (const mac in macData) {
    result.push({
      mac,
      averageTemperature: (macData[mac].tempSum / macData[mac].count).toFixed(
        2
      ),
      averageHumidity: (macData[mac].humiditySum / macData[mac].count).toFixed(
        2
      ),
    });
  }

  return result;
}

function isWithinDateRange(timestamp: any) {
  const currentDate = new Date(); // Current date
  const date = new Date(timestamp);

  // Check if the dates have the same day and year
  const isSameDayAndYear =
    currentDate.getDate() === date.getDate() &&
    currentDate.getFullYear() === date.getFullYear();

  // Check if the timestamp is between 8am and 6pm
  const hours = date.getHours();
  const isWithinTime = hours >= 8 && hours < 18;

  return isSameDayAndYear && isWithinTime;
}

function get_daily_mac_stats(data: any[], day?: string) {
  // Get the unique MAC addresses and their corresponding names
  const macAddressMap = new Map<string, string>();
  data.forEach((item: any) => {
    if (!macAddressMap.has(item.mac)) {
      macAddressMap.set(item.mac, item.name);
    }
  });

  // Get the latest day if not provided
  if (!day) {
    const latestTimestamp = Math.max(
      ...data.map((item: any) => new Date(item.timestamp).getTime())
    );
    const latestDate = new Date(latestTimestamp);
    day = `${latestDate.getFullYear()}-${(latestDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${latestDate.getDate().toString().padStart(2, "0")}`;
  }

  // Define start and end of the specified day
  const startOfDay = new Date(`${day}T00:00:00`);
  const endOfDay = new Date(startOfDay);
  endOfDay.setDate(endOfDay.getDate() + 1);

  // Filter data for the specified day
  const filteredData = data.filter((item: any) => {
    const itemDate = new Date(item.timestamp);
    return itemDate >= startOfDay && itemDate < endOfDay;
  });

  // Group data by MAC address
  const macData = Array.from(macAddressMap.keys()).map((mac: string) => {
    const macDataForDay = filteredData.filter((item: any) => item.mac === mac);
    return { mac, name: macAddressMap.get(mac), data: macDataForDay };
  });

  return macData;
}

export {
  getYearMonthRange,
  name_sensors,
  calculateDailyTemperatureAverage,
  getCurrentMonthAndYear,
  formatDate,
  getColorForTemperature,
  get_min_max,
  mac_stats,
  opts,
  sortDataByHourly,
  month_names,
  latestDate,
  generateColors,
  calculateAverages,
  get_daily_mac_stats,
};
