import React from "react";
import { Dropdown } from "primereact/dropdown";

const OptionsButton = ({ days, onSelectDay, selected_day }: any) => {
  // console.log(days);

  return (
    <div className="flex m-2">
      <Dropdown
        value={selected_day}
        onChange={(e) => onSelectDay(e.value)}
        options={days}
        placeholder="Select a day"
        className="w-full md:w-14rem text-sm bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
      />
    </div>
  );
};

export default OptionsButton;
