import { Divider } from "@aws-amplify/ui-react";
import React, { useEffect, useState } from "react";
import { Bar, Line } from "react-chartjs-2";
import WeatherCard from "../temp_day_card";
import {
  generateColors,
  getColorForTemperature,
  opts,
} from "../../pages/Sensors/state";

function AllSensorStats({ statistics }: any) {
  const [colors, setColors] = useState({} as any);

  useEffect(() => {
    // Generate colors for each dataset

    setColors(generateColors(statistics, "mac"));
  }, [statistics]);

  return (
    <>
      <div className="p-4  ">
        {/* <h1 className="my-4 text-3xl md:text-5xl font-bold ">
          All <span className="text-blue-500">Sensors</span>
        </h1> */}
        <Divider />
        <div className="p-grid p-jc-between p-mb-4 ">
          <div className="flex flex-column  my-4">
            <div className="card bg-base-100 shadow-xl bg-gray-200 mb-4">
              <div className="card-body">
                <p className="stat-title">
                  Daily average temperature for all the sensors
                </p>
              </div>
            </div>
            <div className="flex flex-row justify-content-evenly align-items-center">
              <div className="flex-1 flex flex-column">
                <h1 className="my-4 text-3xl md:text-5xl font-bold ">
                  <span className="text-blue-500">Temperature </span>- Daily
                  Average (8:00 - 16:00)
                </h1>
                <Line
                  datasetIdKey="id"
                  options={opts}
                  data={
                    {
                      labels: (Object.values(statistics)[0] as any)[
                        "temperature"
                      ].map((day: any) =>
                        new Date(day.day).toLocaleDateString("en-US", {
                          month: "long",
                          day: "numeric",
                        })
                      ),
                      datasets: Object.values(statistics).map(
                        (sensors: any) => {
                          return {
                            ...colors[sensors.mac], // Use stored colors
                            id: sensors.mac,
                            label: sensors.name,
                            data: sensors.temperature.map(
                              (day: any) => day.value
                            ),
                          };
                        }
                      ),
                    } as any
                  }
                />
              </div>
              <div className="flex-1 flex flex-column">
                <h1 className="my-4 text-3xl md:text-5xl font-bold ">
                  <span className="text-blue-500">Humidity </span>- Daily
                  Average (8:00 - 16:00)
                </h1>
                <Line
                  datasetIdKey="id"
                  data={
                    {
                      labels: (Object.values(statistics)[0] as any)[
                        "humidity"
                      ].map((day: any) =>
                        new Date(day.day).toLocaleDateString("en-US", {
                          month: "long",
                          day: "numeric",
                        })
                      ),
                      datasets: Object.values(statistics).map(
                        (sensors: any) => {
                          return {
                            ...colors[sensors.mac], // Use stored colors
                            id: sensors.mac,
                            label: sensors.name,
                            data: sensors.humidity.map((day: any) => day.value),
                          };
                        }
                      ),
                    } as any
                  }
                  options={opts}
                />
              </div>
            </div>
          </div>
          <div className="p-col-6">
            {/* <div className="flex flex-col items-start mt-4">
            <p className="text-sm text-gray-600">Humidity Readings:</p>
             <Bar data={chartData} options={chartOptions} /> 
          </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default AllSensorStats;
