import React, { useState, createContext } from "react";
import PropTypes from "prop-types"; // Import PropTypes

const Downloaded_data = createContext({
  downloaded_data: [],
  set_downloaded_data: (_) => {
    //
  },
});

export const Downloaded_data_provider = ({ children }) => {
  const [downloaded_data, set_downloaded_data] = useState([]); // Initialize downloaded_data state

  const value = {
    downloaded_data, // Add downloaded_data to the context value
    set_downloaded_data, // Add set_downloaded_data to the context value
  };

  return (
    <Downloaded_data.Provider value={value}>
      {children}
    </Downloaded_data.Provider>
  );
};

// Add prop validation for the children prop
Downloaded_data_provider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Downloaded_data;
