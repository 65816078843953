import React, { useContext, useEffect, useState } from "react";
import Loading from "../../context/Loading";
import Downloaded_data from "../../context/Donwloaded_data";
import { IonContent, IonItem, IonItemDivider, IonPage } from "@ionic/react";
import { Label, Text } from "@primer/react";
import { Timeline } from "primereact/timeline";
import { Chart } from "primereact/chart";
import Toast_message from "../../context/Toast_message";
import { s3_prifix, url } from "../Home";
import {
  calculateAverages,
  calculateDailyTemperatureAverage,
  formatDate,
  get_daily_mac_stats,
  get_min_max,
  getColorForTemperature,
  getCurrentMonthAndYear,
  getYearMonthRange,
  latestDate,
  mac_stats,
  month_names,
  name_sensors,
  sortDataByHourly,
} from "./state";
import WeatherCard from "../../components/temp_day_card";
import { Divider } from "@aws-amplify/ui-react";
import Curr_temps from "../../context/Curr_temps";
import Sensors_list from "../../context/Sensors";
import SensorStats from "../../components/sensor_stats";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import AllSensorStats from "../../components/AllSensorStats";
import OptionsButton from "../../components/OptionsButton";
import Hourly_Graph from "../../components/Hourly_Graph";
import All_SensorStats from "../../components/All_SensorStats";
import Daily_ from "../../components/Daily_";

const Sensors = () => {
  const { set_loading } = useContext(Loading);
  const { toast }: any = useContext(Toast_message);
  const { curr_temp }: any = useContext(Curr_temps);
  const { sensors } = useContext(Sensors_list);
  const { downloaded_data, set_downloaded_data } = useContext(Downloaded_data);

  const [stats, set_stats] = useState([] as any);
  const [selected_month, set_selected_month] = useState(null as any);
  const [selected_day, set_selected_day] = useState(
    `${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(new Date().getDate()).padStart(2, "0")}`
  );

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  // useEffect(() => {
  //   if (stats) console.log(stats);
  // }, [stats]);

  useEffect(() => {
    if (downloaded_data.length)
      update_stats(getCurrentMonthAndYear(downloaded_data));
  }, [downloaded_data]);

  const update_stats = (month: any) => {
    set_selected_month(month);
    set_selected_day(latestDate(month, downloaded_data));

    set_stats((prev: any) => {
      return {
        ...prev,
        ...get_min_max(downloaded_data),
        daily_temp: calculateDailyTemperatureAverage(
          downloaded_data,
          parseInt(month.split(" ")[0]),
          parseInt(month.split(" ")[1])
        ),
        months: getYearMonthRange(downloaded_data),
        mac_stats: mac_stats(downloaded_data, month),
        mac_hourly: get_daily_mac_stats(downloaded_data),
        hourly_temp: sortDataByHourly(
          downloaded_data,
          latestDate(month, downloaded_data)
        ),
      };
    });
  };

  const update_selected_day = (day_month: any) => {
    // day = 08 May

    // set_selected_day(latestDate(month, downloaded_data));
    // Split the year and month
    const [year, month] = selected_month.split(" ");
    const [day, _] = day_month.split(" ");

    // console.log(get_daily_mac_stats(downloaded_data));

    // Pad the month with leading zero if needed (to ensure two digits)
    const paddedMonth = month.padStart(2, "0");

    // Concatenate the year, month, and day with hyphens
    const dateString = `${year}-${paddedMonth}-${day
      .toString()
      .padStart(2, "0")}`;

    console.log(dateString);

    set_selected_day(dateString);
    console.log(get_daily_mac_stats(downloaded_data, dateString));

    set_stats((prev: any) => {
      return {
        ...prev,
        mac_hourly: get_daily_mac_stats(downloaded_data, dateString),
        hourly_temp: sortDataByHourly(downloaded_data, dateString),
      };
    });
  };
  return (
    <IonPage
      style={{
        marginTop: "5vh",
        overflowY: "auto", // Allow vertical scrolling
        maxHeight: "95vh", // Set a maximum height for the scrollable area
      }}
      className="block px-3"
    >
      <div className=" border rounded-lg shadow-lg">
        <div>
          <div className="p-4 my-4 ">
            <h1 className=" text-3xl md:text-5xl font-bold ">
              Temperature Statistics -
              <span className="text-blue-500">
                {" "}
                {formatDate(selected_month).split(" ")[1]}
              </span>
            </h1>
            <Divider />
          </div>

          <div className="flex flex-row align-items-start">
            <div className="card bg-base-100 shadow-xl  bg-gray-200 p-2 mx-2">
              <ul className="timeline timeline-vertical">
                {stats?.months?.map((month: any) => (
                  <li key={month} className="flex">
                    <div className="timeline-middle">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        className="w-7 h-7"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <div
                      onClick={() => update_stats(month)}
                      className="timeline-end timeline-box  cursor-pointer"
                    >
                      {month}
                    </div>
                  </li>
                ))}
              </ul>
            </div>

            <div className="card bg-base-100 shadow-xl bg-gray-200">
              <div className="card-body">
                <div className="stat-title">Monthy Ave Temperature</div>
                <div
                  style={{
                    color: getColorForTemperature(
                      parseInt(
                        (
                          stats?.daily_temp
                            ?.map((day: any) => day.temp)
                            ?.reduce(
                              (accumulator: any, currentValue: any) =>
                                accumulator + currentValue,
                              0
                            ) / stats.daily_temp?.length
                        ).toFixed(2)
                      )
                    ),
                  }}
                  className="stat-value"
                >
                  {(
                    stats?.daily_temp
                      ?.map((day: any) => day.temp)
                      ?.reduce(
                        (accumulator: any, currentValue: any) =>
                          accumulator + currentValue,
                        0
                      ) / stats.daily_temp?.length
                  ).toFixed(2)}{" "}
                  °C
                </div>
                <div className="stat-desc">
                  Average of temperature from 08:00 to 16:00
                </div>
                {/* <p>
              {stats?.daily_temp?.reduce(
                (acc: any, temperature: any) => acc + temperature,
                0
              ) / stats?.daily_temp?.length}
            </p> */}
              </div>
            </div>

            <div className="flex flex-1 flex-direction-row flex-wrap justify-content-start align-items-center px-2 grid grid-cols-1 md:grid-cols-2 ">
              {stats?.daily_temp?.map((day: any, i: any) => (
                <WeatherCard
                  data={{ ...day, timestamp: formatDate(day.timestamp) }}
                  min={stats.min_temp}
                  max={stats.max_temp}
                  key={i}
                />
              ))}
            </div>
          </div>
          {stats?.mac_stats && <AllSensorStats statistics={stats.mac_stats} />}
        </div>
      </div>
      {/* <Label className="m-2">
        Records Count: {Object.keys(downloaded_data).length}
      </Label> */}
      <div className="flex p-4 ">
        <WeatherCard
          data={{
            timestamp: "Maximum",
            temp: stats.max_temp,
            humidity: stats.max_humidity,
          }}
          min={stats.min_temp}
          max={stats.max_temp}
        />

        <WeatherCard
          data={{
            timestamp: "Minimum",
            temp: stats.min_temp,
            humidity: stats.min_humidity,
          }}
          min={stats.min_temp}
          max={stats.max_temp}
        />
      </div>
      <Divider className="m-3" />
      <div className="p-4 border rounded-lg shadow-lg ">
        <div>
          <h1 className="my-4 text-3xl md:text-5xl font-bold ">
            Hourly Temperature{" "}
            <span className="text-blue-500">
              for {formatDate(selected_day)}
            </span>
          </h1>

          <OptionsButton
            days={stats?.daily_temp?.map(
              (day: any) =>
                `${
                  day.day +
                  " " +
                  month_names[parseInt(selected_month.split(" ")[1], 10) - 1]
                }`
            )}
            onSelectDay={update_selected_day}
            selected_day={selected_day}
          />

          {stats?.mac_hourly && <Daily_ statistics={stats.mac_hourly} />}

          <div className="card bg-base-100 shadow-xl bg-gray-200 mb-4">
            <div className="card-body">
              <p className="stat-title">
                Hourly average temperature and Humidity
              </p>
            </div>
          </div>
          <Hourly_Graph data={stats?.hourly_temp} />

          <div className="flex flex-1 flex-direction-row flex-wrap justify-content-start align-items-center px-2 grid grid-cols-1 md:grid-cols-2 ">
            {!!stats?.hourly_temp?.length &&
              stats?.hourly_temp?.map((hour: any, i: any) => {
                if (hour.hour >= 8 && hour.hour <= 16)
                  return (
                    <WeatherCard
                      data={{ ...hour, timestamp: hour.hour + ":00" }}
                      min={stats.min_temp}
                      max={stats.max_temp}
                      key={i}
                    />
                  );
              })}
          </div>
        </div>
      </div>

      <Divider className="m-3" />

      {/* <All_SensorStats statistics={stats.mac_stats} /> */}

      <div>
        {Object.keys(stats).length &&
          Object.keys(stats.mac_stats).length &&
          Object.values(stats.mac_stats).map((sense: any) => (
            <SensorStats key={sense.mac} statistics={sense} />
          ))}
      </div>

      {/* <Chart type="line" data={data} options={options} /> */}
    </IonPage>
  );
};

export default Sensors;
