import React, { useState, createContext } from "react";
import PropTypes from "prop-types"; // Import PropTypes

const Tfg_data = createContext({
  tfg_data: new Object(),
  set_tfg_data: (_) => {
    //
  },
});

export const Tfg_data_provider = ({ children }) => {
  const [tfg_data, set_tfg_data] = useState(new Object());

  const value = {
    tfg_data,
    set_tfg_data,
  };

  return <Tfg_data.Provider value={value}>{children}</Tfg_data.Provider>;
};
// Add prop validation for the children prop
Tfg_data_provider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Tfg_data;
