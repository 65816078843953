import { Divider } from "@aws-amplify/ui-react";
import React, { useContext, useEffect } from "react";
import { Bar, Line } from "react-chartjs-2";
import WeatherCard from "../temp_day_card";
import { getColorForTemperature, opts } from "../../pages/Sensors/state";
import Curr_temps from "../../context/Curr_temps";

function SensorStats({ statistics }: any) {
  // Define chart options
  //   console.log(statistics);
  const { curr_temps }: any = useContext(Curr_temps);
  // useEffect(() => {
  //   console.log(curr_temps);
  //   console.log(statistics);
  //   // console.log(curr_temps[statistics.mac].temperature);
  // }, [curr_temps]);
  return (
    <>
      <div className="p-4 border rounded-lg shadow-lg ">
        <h1 className="my-4 text-3xl md:text-5xl font-bold ">
          {statistics.name}
        </h1>
        <h1 className="text-blue-500 my-4 text-1xl">{statistics.mac}</h1>
        <Divider />
        <div className="p-grid p-jc-between p-mb-4">
          <div className="p-col-6">
            <div className="flex flex-col items-start m-4">
              <p className="text-sm text-gray-600">
                Temperature and Humidy graphs
              </p>
              <div className="flex flex-row">
                <div
                  style={{ height: "60vh", width: "60vw" }}
                  className="flex text-sm"
                >
                  <Line
                    options={opts}
                    datasetIdKey="id"
                    data={
                      {
                        labels: statistics.temperature.map((day: any) =>
                          new Date(day.day).toLocaleDateString("en-US", {
                            month: "long",
                            day: "numeric",
                          })
                        ),
                        datasets: [
                          {
                            id: "Humidity",
                            label: "Humidity",
                            data: statistics.humidity.map(
                              (day: any) => day.value
                            ),
                            backgroundColor: "rgba(75, 192, 192, 0.2)", // Background color for humidity
                            borderColor: "rgba(75, 192, 192, 1)", // Border color for humidity
                          },
                          {
                            id: "Temperature",
                            label: "Temperature",
                            data: statistics.temperature.map(
                              (day: any) => day.value
                            ),
                            backgroundColor: "rgba(255, 99, 132, 0.2)", // Background color for temperature
                            borderColor: "rgba(255, 99, 132, 1)", // Border color for temperature
                          },
                        ],
                      } as any
                    }
                  />
                </div>
                <div>
                  <div className="flex flex-row flex-wrap">
                    <WeatherCard
                      data={{
                        timestamp: "Minimum",
                        temp: statistics.minTemperature,
                        humidity: statistics.minHumidity,
                      }}
                      min={15}
                      max={30}
                    />
                    <WeatherCard
                      data={{
                        timestamp: "Average ",
                        temp: statistics.avgTemperature,
                        humidity: statistics.avgHumidity,
                      }}
                      min={15}
                      max={30}
                    />
                    <WeatherCard
                      data={{
                        timestamp: "Maximun",
                        temp: statistics.maxTemperature,
                        humidity: statistics.maxHumidity,
                      }}
                      min={15}
                      max={30}
                    />
                    {!!Object.keys(curr_temps).length &&
                      !!Object.keys(curr_temps).includes(statistics.mac) && (
                        <WeatherCard
                          data={{
                            timestamp: "Current",
                            temp: curr_temps[statistics.mac].temperature,
                            humidity: curr_temps[statistics.mac].humidity,
                          }}
                          min={15}
                          max={30}
                        />
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="p-col-6">
            {/* <div className="flex flex-col items-start mt-4">
            <p className="text-sm text-gray-600">Humidity Readings:</p>
             <Bar data={chartData} options={chartOptions} /> 
          </div> */}
          </div>
        </div>
      </div>
      <Divider className="my-3" />
    </>
  );
}

export default SensorStats;
