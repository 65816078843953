/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://rx3osb5vcfadrapyzchistbqrm.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-dredkt2lbjfmlfdh5s5edsecxy",
    "API": {
        "GraphQL": {
            "endpoint": "https://rx3osb5vcfadrapyzchistbqrm.appsync-api.us-east-1.amazonaws.com/graphql",
            "region": "us-east-1",
            "defaultAuthMode": "apiKey",
            "apiKey": "da2-dredkt2lbjfmlfdh5s5edsecxy"
        }
    }
};


export default awsmobile;
