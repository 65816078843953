import { IonApp, IonRouterOutlet } from "@ionic/react";
import React, { useContext, useEffect } from "react";
import Home from "../pages/Home";
import Sensors from "../pages/Sensors";
import { Toast } from "primereact/toast";
import Toast_message from "../context/Toast_message";
import Loading from "../context/Loading";
import { ProgressSpinner } from "primereact/progressspinner";
import Header from "../components/header";
import { Route, Switch } from "react-router-dom";

import { IonReactRouter } from "@ionic/react-router";

const Stack = () => {
  const { loading, set_loading } = useContext(Loading);
  const { toast } = useContext(Toast_message);

  return (
    <IonApp>
      <IonReactRouter>
        <Header />
        <IonRouterOutlet>
          <Switch>
            <Route path="/Sensors" component={Sensors} />
            <Route exact path="/" component={Home} />
          </Switch>
        </IonRouterOutlet>
        {/* loading */}
        <div
          style={{
            zIndex: 10000,
            position: "absolute",
            display: loading ? "flex" : "none",
            justifyContent: "center",
            alignItems: "center",
            backdropFilter: "blur(8px)",
            width: "100%",
            height: "100%",
            top: 0,
            right: 0,
            overflow: "hidden",
          }}
        >
          <ProgressSpinner
            style={{ width: "50px", height: "50px" }}
            strokeWidth="8"
            color="primary"
          />
        </div>
        <Toast
          style={{
            zIndex: 10002,
          }}
          ref={toast}
        />
      </IonReactRouter>
    </IonApp>
  );
};

export default Stack;
